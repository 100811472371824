import create from "zustand";

const initialActiveProjectValue = null;
const initialActiveSkillValue = null;
const initialCameraPositionValue = null;
const initialCurrentSection = 0;

const useStore = create((set) => ({
  progress: { total: 0, loaded: 0, finished: false, ready: false },
  updateProgress: (newProgress) =>
    set((state) => ({ progress: { ...state.progress, ...newProgress } })),

  activeProject: initialActiveProjectValue,
  updateActiveProject: (activeProject) => set({ activeProject }),

  activeSkill: initialActiveSkillValue,
  updateActiveSkill: (activeSkill) => set({ activeSkill }),

  cameraPosition: initialCameraPositionValue,
  updateCameraPosition: (cameraPosition) => set({ cameraPosition }),

  currentSection: initialCurrentSection,
  updateCurrentSection: (currentSection) => set({ currentSection }),
}));

export default useStore;
