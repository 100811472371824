import { I18nextProvider } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes } from "./Routes";
import i18n from "./i18n";
import { ThemeProvider } from "@emotion/react";
import Loading from "./components/Loading";
import { useProgress } from "@react-three/drei";
import useStore from "./shared/useStore";
import { useEffect, useState } from "react";

export const theme = {
  accentColor: "#AD9CFF",
  breakpoint: "max-width: 780px",
  headingFadeInDelayInMs: 800,
};

function App() {
  const progress = useProgress();
  const [faked, isFaked] = useState(false);

  const updateProgress = useStore((state) => state.updateProgress);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      isFaked(true);
    }, 5000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    updateProgress({
      total: progress.total,
      loaded: progress.loaded,
      finished:
        faked && progress.total > 0 && progress.loaded === progress.total,
    });
  }, [faked, progress, updateProgress]);

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <Loading />
          <Helmet titleTemplate="%s | Dorian G" />
          <Routes />
        </HelmetProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
