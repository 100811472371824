import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SectionContent, SectionSmallHeading } from "../shared/Section";
import useStore from "../shared/useStore";

const Content = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  isolation: isolate;
  z-index: 9999;
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  pointer-events: ${(props) => (props.loaded ? "none" : "auto")};
  transition: opacity 0.4s;
`;

const Fact = styled.p`
  opacity: 0.7;
  line-height: 1.5;
  max-width: 540px;
`;

const flashing = keyframes`
0% {
  background-color: #ffffff;
}
50%,
100% {
  background-color: #000000;
}
`;

const Dots = styled.span`
  display: inline-block;
  margin-left: 4px;
  position: relative;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: #ffffff;
  animation: ${flashing} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: #ffffff;
  }

  &::before {
    left: -4px;
    animation: ${flashing} 1s infinite linear alternate;
    animation-delay: 0s;
  }

  &::after {
    right: -4px;
    animation: ${flashing} 1s infinite linear alternate;
    animation-delay: 1s;
  }
`;

const Progress = styled.div`
  margin-top: auto;
  width: 100%;
`;

const Bar = styled.div`
  position: relative;
  height: 6px;
  width: 100%;
  margin-top: 0.5rem;
  &::after {
    content: "";
    display: block;
    width: ${(props) => props.progress}%;
    height: 100%;
    background-color: ${(props) => props.theme.accentColor};
  }
`;

function leadingZero(num) {
  let s = num + "";
  while (s.length < 3) s = "0" + s;
  return s;
}

const Loading = () => {
  const { t } = useTranslation();

  const total = useRef(10);
  const loaded = useRef(0);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    useStore.subscribe(
      (progress) => {
        total.current = progress.total;
        loaded.current = progress.loaded;
        setFinished(progress.finished);
      },
      (state) => state.progress
    );
  }, []);

  return finished ? null : (
    <Content loaded={finished}>
      <SectionContent height={"100%"}>
        <SectionSmallHeading>
          {t("Loading.title")}
          <Dots />
        </SectionSmallHeading>
        <Fact>
          <Trans i18nKey={"Loading.fact"} />
        </Fact>
        <Progress>
          {loaded.current && total.current && (
            <>
              {leadingZero(Math.floor((loaded.current / total.current) * 100))}
              <Bar
                progress={Math.floor((loaded.current / total.current) * 100)}
              />
            </>
          )}
        </Progress>
      </SectionContent>
    </Content>
  );
};

export default Loading;
