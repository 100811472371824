import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const Main = lazy(() => import("./components/Main"));

export const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route path="*" component={Main} exact />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
