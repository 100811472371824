import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Text = css`
  font-size: 0.9rem;
  line-height: 1.8;
  opacity: 0.7;
  font-weight: 400;
`;

export const SectionText = styled.p`
  ${Text};
  text-align: ${(props) => (props.textAlign ? props.textAlign : undefined)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : undefined)};
  @media (${(props) => props.theme.breakpoint}) {
    line-height: 1.5;
  }
  a {
    color: currentColor;
    &:hover {
      color: ${(props) => props.theme.accentColor};
    }
  }
`;

export const SectionLargeText = styled.p`
  ${Text};
  font-size: 1rem;
  font-weight: 500;
  text-align: ${(props) => (props.textAlign ? props.textAlign : undefined)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : undefined)};
  margin-bottom: 1.2rem;
  @media (${(props) => props.theme.breakpoint}) {
    font-size: 0.85rem;
    text-align: left;
    line-height: 1.5;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 2.6rem;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 1.2rem;
  text-align: ${(props) => (props.textAlign ? props.textAlign : undefined)};
  & strong {
    font-weight: inherit;
    color: ${(props) => props.theme.accentColor};
  }
  @media (${(props) => props.theme.breakpoint}) {
    font-size: 1.7rem;
    text-align: left;
  }
`;

export const SectionSmallHeading = styled.p`
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
`;

export const Container = styled.div`
  position: absolute;
  top: 10vh;
  display: flex;
  height: 90%;
  width: 100%;
  padding: 5vh 5vw;
  align-items: center;
  justify-content: ${(props) =>
    props.align === "right" ? "flex-end" : "flex-start"};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  width: 100%;
  height: auto;
  align-items: ${(props) =>
    props.align === "right" ? "flex-end" : "flex-start"};
  height: ${(props) => (props.height ? props.height : undefined)};
  @media (${(props) => props.theme.breakpoint}) {
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }
`;

export const SectionContent = ({ align, height, children, ...props }) => (
  <Container align={align} {...props}>
    <Content align={align} height={height}>
      {children}
    </Content>
  </Container>
);
